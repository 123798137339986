import Picture from 'next-export-optimize-images/picture'
import feature1 from './feature-1.png'
import feature2 from './feature-2.png'
import feature3 from './feature-3.png'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

const Features = () => {
  const { t } = useTranslation('resumes-for-australia')

  const features = [
    {
      image: feature1
    },
    {
      image: feature2
    },
    {
      image: feature3
    }
  ]

  return (
    <div className='space-y-6'>
      {features.map((feature, index) => (
        <div
          key={index}
          className='text-gray-600 grid md:grid-cols-2 items-center gap-8'
        >
          <div
            className={classNames('space-y-4 p-4 md:p-12 col-span-1', {
              'md:text-right': index % 2 === 1
            })}
          >
            <h3 className='text-xl md:text-3xl font-semibold text-gray-800 capitalize mb-8'>
              {t(`feature-${index + 1}-attention`)}
            </h3>
            <p>{t(`feature-${index + 1}-interest`)}</p>
            <p>{t(`feature-${index + 1}-desire`)}</p>
          </div>
          <div
            className={classNames('col-span-1 flex justify-start', {
              'md:-order-1': index % 2 === 0,
              'justify-end': index % 2 === 0
            })}
          >
            <Picture
              src={feature.image}
              alt={t(`feature-${index + 1}-attention`)}
              width={600}
              height={600}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default Features
