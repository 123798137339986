import Header from '../components/header/header'

function FullWidthLayout({ children }: any) {
  return (
    <div className='w-full min-h-screen'>
      <Header />
      <div className='space-y-2'>{children}</div>
    </div>
  )
}

export default FullWidthLayout
