import languageDetector from '@/services/language-detector'
import { SupportedLanguage } from '@rebelresumes/contracts'
import { useTranslation } from 'next-i18next'

/**
 * These Ids are written in Cloudflare Streams in Andrew's account
 */
const videoSrcIds: Record<SupportedLanguage, string> = {
  en: 'fa57ad1e7399484ee6166430c79e2ebc',
  zh: 'ecfca652a86c41ba66f5711ee4f22c21',
  ja: '5cd1978c4623ab4ddb3ae3966945ab89',
  ko: '0d999a333875c7aa82bbe428fb9b5fd4',
  es: 'ea81cae5eb88429eb577fec7f4e413e1',
  fr: '6c4cb708ff2b536b6e473df9462191fd',
  pt: '965f4308f953a775870f100f59b8133a',
  mn: '2387abcf69b0d9e80c3ca2d984e58a84',
  th: 'fa57ad1e7399484ee6166430c79e2ebc', // Use English version while waiting for Thai version
  hi: 'fa57ad1e7399484ee6166430c79e2ebc',
  ne: 'fa57ad1e7399484ee6166430c79e2ebc',
  tl: 'fa57ad1e7399484ee6166430c79e2ebc',
  vi: 'fa57ad1e7399484ee6166430c79e2ebc',
  ur: 'fa57ad1e7399484ee6166430c79e2ebc',
  id: 'fa57ad1e7399484ee6166430c79e2ebc',
  ms: 'fa57ad1e7399484ee6166430c79e2ebc'
}

const ExplainerVideo = () => {
  const { t } = useTranslation('resumes-for-australia')

  const language = languageDetector.detect() as SupportedLanguage
  const srcId = videoSrcIds[language]
  const src = `https://customer-v1kkxag61cj0w4i0.cloudflarestream.com/${srcId}/iframe?preload=true}`

  const thumbnailUrl = src.replace(
    /\/iframe.*/,
    '/thumbnails/thumbnail.jpg?time=30s&height=600'
  )
  const poster = `poster=${encodeURIComponent(thumbnailUrl)}`
  const srcWithPoster = `${src}&${poster}`

  return (
    <div className='grid lg:grid-cols-2 gap-8 text-white px-8'>
      <div className='relative pt-[56.25%]'>
        <iframe
          src={srcWithPoster}
          className='rounded-xl border-0 absolute top-0 left-0 w-full h-full bg-white'
          allow='accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;'
          allowFullScreen={true}
        ></iframe>
      </div>
      <p className='text-xl lg:text-2xl font-light flex items-center'>
        {t('hero-subsubtitle')}
      </p>
    </div>
  )
}

export default ExplainerVideo
