import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTwitter,
  IconBrandYoutube
} from '@tabler/icons-react'
import Link from '../link/link'
import { useTranslation } from 'next-i18next'

const navigation = {
  main: [
    { name: 'about', href: '#' },
    { name: 'blog', href: '#' },
    // { name: 'Jobs', href: '#' },
    // { name: 'Press', href: '#' },
    { name: 'accessibility', href: '#' },
    { name: 'affiliates', href: '#' },
    { name: 'contact us', href: '/contact/' }
  ],
  social: [
    {
      name: 'Facebook',
      href: '#',
      icon: () => <IconBrandFacebook />
    },
    {
      name: 'Instagram',
      href: '#',
      icon: () => <IconBrandInstagram />
    },
    {
      name: 'Twitter',
      href: '#',
      icon: () => <IconBrandTwitter />
    },
    {
      name: 'YouTube',
      href: '#',
      icon: () => <IconBrandYoutube />
    }
  ]
}

const Footer = () => {
  const { t } = useTranslation('footer')

  return (
    <footer className='bg-white'>
      <div className='mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8'>
        <nav
          className='-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12'
          aria-label='Footer'
        >
          {navigation.main.map(item => (
            <div key={item.name} className='pb-6'>
              <Link
                href={item.href}
                className='text-sm leading-6 text-gray-600 hover:text-gray-900'
              >
                {t(item.name)}
              </Link>
            </div>
          ))}
        </nav>
        <div className='mt-10 flex justify-center space-x-10'>
          {navigation.social.map(item => (
            <a
              key={item.name}
              href={item.href}
              className='text-gray-400 hover:text-gray-500'
            >
              <span className='sr-only'>{item.name}</span>
              <item.icon aria-hidden='true' />
            </a>
          ))}
        </div>
        <p className='mt-10 text-center text-xs leading-5 text-gray-500'>
          &copy; {new Date().getFullYear()} RebelResumes.{' '}
          {t('all rights reserved')}.
        </p>
      </div>
    </footer>
  )
}

export default Footer
