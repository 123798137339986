import { Payment } from '@stripe/firestore-stripe-payments'
import { configuration } from './configuration'
import { getAuth, getFirestore } from './firebase'
import { collection, getDocs } from 'firebase/firestore'

class PaymentService {
  // Per https://stripe.com/docs/payments/checkout
  async createCheckoutSession(
    priceId: string,
    resumeId: string,
    successUrl: string,
    cancelUrl: string,
    locale: string
  ): Promise<string> {
    const authToken = await getAuth().currentUser!.getIdToken()

    // POST request to /paymentCreateCheckoutSession
    const response = await fetch(
      configuration.functionsBaseUrl() + '/paymentCreateCheckoutSession',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        body: JSON.stringify({
          priceId,
          resumeId,
          successUrl,
          cancelUrl,
          locale
        })
      }
    )
    const result = await response.json()
    if (response.status >= 400) {
      throw new Error(result.message)
    }
    return result.checkoutUrl
  }

  async list(): Promise<Payment[]> {
    const userId = await getAuth().currentUser!.uid
    const docs = await getDocs(
      collection(getFirestore(), `customers/${userId}/payments`)
    )
    const payments: Payment[] = []
    docs.forEach(doc => {
      const payment = doc.data() as Payment
      Object.assign(payment, { id: doc.id })
      payments.push(payment)
    })

    return payments
  }
}

export const paymentService = new PaymentService()
