import { useState, useEffect } from 'react'

// Why does this exist? https://docs.pmnd.rs/zustand/integrations/persisting-store-data#usage-in-next.js
const useNextJsStore = <StateType, PropertyType>(
  store: (callback: (state: StateType) => unknown) => unknown,
  callback: (state: StateType) => PropertyType
) => {
  const result = store(callback) as PropertyType
  const [data, setData] = useState<PropertyType>()

  useEffect(() => {
    setData(result)
  }, [result])

  return data
}

export default useNextJsStore
