import Link from '@/components/link/link'
import Button from '@/components/tailwind/button'
import { IconArrowRight } from '@tabler/icons-react'
import { useTranslation } from 'next-i18next'

const MidPageCta = () => {
  const { t } = useTranslation('resumes-for-australia')

  return (
    <div className='max-w-[1000px] mx-auto text-center space-y-8'>
      <h2 className='text-3xl md:text-5xl capitalize'>
        {t('midpage-cta-header')}
      </h2>
      <p className='text-xl'>{t('midpage-cta-sub')}</p>
      <div className='flex justify-center'>
        <Link href='/resumes/'>
          <Button
            variant='outlined'
            className='animate-heartbeat flex font-normal items-center w-full justify-center lg:w-auto text-xl bg-white text-primary-600'
          >
            <span>{t('midpage-cta-button')}</span>
            <IconArrowRight />
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default MidPageCta
