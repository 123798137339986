import { useTranslation } from 'next-i18next'

const Faq = () => {
  const faqs = new Array(5).fill(undefined)

  const { t } = useTranslation('resumes-for-australia')

  return (
    <div className='space-y-4'>
      <h2 className='text-3xl text-center'>{t('faq-header')}</h2>

      <dl className='space-y-8 divide-y divide-gray-200'>
        {faqs.map((faq, index) => (
          <div key={index} className='pt-8 lg:grid lg:grid-cols-12 lg:gap-8'>
            <dt className='text-base font-semibold leading-7 text-gray-900 lg:col-span-5'>
              {t(`faq-question-${index + 1}`)}
            </dt>
            <dd className='mt-4 lg:col-span-7 lg:mt-0'>
              <p className='text-base leading-7 text-gray-600'>
                {t(`faq-answer-${index + 1}`)}
              </p>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

export default Faq
