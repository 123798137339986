import { collection, getDocs, query, where } from 'firebase/firestore'
import { getFirestore } from './firebase'
import { StripeProduct } from '@rebelresumes/contracts'

class ProductService {
  async list(): Promise<StripeProduct[]> {
    // Queries Firestore directly, as getProducts from stripe failed to work with latest version of the firebase sdk

    const docs = await getDocs(collection(getFirestore(), 'products'))
    const products: StripeProduct[] = []
    docs.forEach(doc => {
      const product = doc.data() as StripeProduct
      if (product.active) {
        Object.assign(product, { id: doc.id })
        products.push(product)
      }
    })

    const fetchPrices = products.map(async product => {
      // Query firebase for the price which has active = true
      const pricesCollection = collection(
        getFirestore(),
        `products/${product.id}/prices`
      )
      const activePriceQuery = query(
        pricesCollection,
        where('active', '==', true)
      )
      const prices = await getDocs(activePriceQuery)
      const price = prices.docs[0].data()
      price.id = prices.docs[0].id

      Object.assign(product, { prices: [price] })
    })

    await Promise.all(fetchPrices)

    return products
  }
}

export const productService = new ProductService()
