import { Plan, plans as defaultPlans } from '@rebelresumes/contracts'
import { IconCheck, IconShoppingCartPlus } from '@tabler/icons-react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import languageDetector from '../../services/language-detector'
import { paymentService } from '../../services/payment-service'
import { stripeUnitsToPrice, usePaymentStore } from '../../stores/payment-store'
import Loading from '../loading/loading'
import Spinner from '../spinner/spinner'
import Button from '../tailwind/button'
import useNextJsStore from '../use-nextjs-store/use-nextjs-store'
import { useTranslation } from 'next-i18next'
import { useStore } from '@/stores/store'

const EmptyState = () => (
  <div>
    <Loading />
  </div>
)

interface PlanSelectorProps {
  hidePurchaseButtons?: boolean
  resumeId?: string
}

const PlanSelector: React.FC<PlanSelectorProps> = ({
  hidePurchaseButtons,
  resumeId
}) => {
  const paymentStore = useNextJsStore(usePaymentStore, s => s)
  const [plans, setPlans] = useState<Plan[]>(defaultPlans)
  const [planBeingPurchased, setPlanBeingPurchased] = useState<
    Plan | undefined
  >()
  const { t } = useTranslation('upgrade')
  const store = useNextJsStore(useStore, s => s)

  const router = useRouter()

  useEffect(() => {
    const run = async () => {
      if (paymentStore?.fetchProducts) {
        await paymentStore?.fetchProducts()
      }

      if (paymentStore?.products) {
        // Augment plans with stripe data
        const augmentedPlans = plans.map(plan => {
          const selectedProduct = paymentStore.products!.find(
            product => plan.name === product.name
          )
          if (!plan) {
            throw new Error('Could not map plan to Stripe')
          }
          plan.stripe = selectedProduct
          return plan
        })
        setPlans(augmentedPlans)
      }
    }
    void run()
  }, [paymentStore])

  if (!paymentStore || plans[0].stripe === undefined) {
    return <EmptyState />
  }

  const { products } = paymentStore

  if (!products) {
    return <EmptyState />
  }

  const handleChoosePlan = async (plan: Plan) => {
    if (!resumeId) {
      throw new Error('No resume id provided')
    }
    setPlanBeingPurchased(plan)
    const language = languageDetector.detect()
    const cancelUrl = window.location.href
    const action = router.query.action as string
    const successUrl = `${window.location.origin}/${language}/resumes/8-download/?payment=complete&action=${action}`
    try {
      const redirectUrl = await paymentService.createCheckoutSession(
        plan.stripe!.prices[0].id,
        resumeId,
        successUrl,
        cancelUrl,
        language ?? 'auto' // 'auto' instructs stripe to detect locale
      )
      await router.push(redirectUrl)
    } catch (error) {
      store!.setPrompt({
        message: (error as Error).message
      })
      setPlanBeingPurchased(undefined)
    }
  }

  return (
    <div className='grid grid-cols-1 gap-4 sm:grid-cols-1 max-w-[400px] mx-auto'>
      {plans.map((plan, index) => (
        <div
          key={index}
          className={classNames(
            'flex-1  bg-white space-y-4 border border-gray-300 rounded-2xl card p-0',
            { 'hover:border-primary-600': !hidePurchaseButtons }
          )}
        >
          <div
            className={classNames(
              'bg-gray-700 text-white rounded-2xl p-8 space-y-1 relative',
              { 'bg-primary-700': plan.name === 'Joey' }
            )}
          >
            <h3 className='text-2xl text-center'>{plan.name}</h3>

            {/* <div className='flex items-center flex-col pt-4 xs:pt-0 xs:absolute text-gray-200'>
              {!!plan.stripe?.prices[0].metadata?.caption && (
                <div className='mb-1 -mt-1 -ml-5 -rotate-[18deg] text-sm'>
                  <span className='bg-primary-700 p-2 rounded-full'>
                    {plan.stripe.prices[0].metadata.caption}
                  </span>
                </div>
              )}
              <div>
                <span>$</span>
                <span className='text-3xl line-through font-regular'>56</span>
              </div>
            </div> */}

            <div className='flex justify-center items-start gap-2'>
              {plan.stripe && (
                <>
                  <span className='text-gray-200 -mr-2'>$</span>
                  <div>
                    <span className='text-7xl font-bold'>
                      {stripeUnitsToPrice(plan.stripe.prices[0].unit_amount!)}
                    </span>
                    <span className='text-base text-gray-200'>
                      {plan.stripe.prices[0].currency.toUpperCase()}
                    </span>
                  </div>
                </>
              )}
            </div>
            <p className='text-center text-sm text-gray-200'>
              {t('per resume')}
            </p>
          </div>

          <div className='px-8 pt-4 pb-8 space-y-4'>
            <p className='text-center font-semibold uppercase text-2xl text-primary-700'>
              {t(plan.name.toLowerCase() + '-description')}
            </p>

            <ul className='space-y-1'>
              {['1', '2', '3']
                .map(num => t(`${plan.name.toLowerCase()}-features-${num}`))
                .filter(feature => !feature.includes('-features-'))
                .map((feature, j) => (
                  <li key={j} className='flex gap-4 items-center'>
                    <IconCheck className='w-4 h-4 text-primary-600' /> {feature}
                  </li>
                ))}
            </ul>

            {!hidePurchaseButtons && (
              <Button
                className='w-full'
                onClick={async () => handleChoosePlan(plan)}
                disabled={!!planBeingPurchased}
              >
                {planBeingPurchased && planBeingPurchased.name === plan.name ? (
                  <div className='flex items-center justify-center gap-4'>
                    {t('redirecting to checkout')} <Spinner />
                  </div>
                ) : (
                  <>
                    <IconShoppingCartPlus /> {t('purchase plan')}
                  </>
                )}
              </Button>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default PlanSelector
