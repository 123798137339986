import { FirebaseApp, initializeApp } from 'firebase/app'
import { getAuth as initializeAuth } from 'firebase/auth'
import {
  Firestore,
  getFirestore as initializeFirestore
} from 'firebase/firestore'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Configured per https://firebase.google.com/docs/auth/web/redirect-best-practices#update-authdomain
const firebaseConfig = {
  apiKey: 'AIzaSyDAHsdp97gUman07fnhrplJeooQ14R6LHc',
  authDomain: 'rebelresumes-com.firebaseapp.com',
  projectId: 'rebelresumes-com',
  storageBucket: 'rebelresumes-com.appspot.com',
  messagingSenderId: '793765842133',
  appId: '1:793765842133:web:eca6927847505a6f98265c',
  measurementId: 'G-M4SYZBPX0G'
}

// Initialize Firebase
let app: FirebaseApp | undefined
// let analytics: Analytics | undefined = getAnalytics(app)
let firestore: Firestore | undefined

export const getApp = () => {
  if (!app) {
    app = initializeApp(firebaseConfig)
  }
  return app
}

// Initialize here so that getAuth etc works

export const getFirestore = () => {
  if (!firestore) {
    firestore = initializeFirestore(getApp())
  }

  return firestore
}

export const getAuth = () => initializeAuth(getApp())
