import bbc from '../../../public/companies-using-resume-roo/bbc.svg'
import britishAirways from '../../../public/companies-using-resume-roo/british-airways.svg'
import lenovo from '../../../public/companies-using-resume-roo/lenovo.svg'
import lufthansa from '../../../public/companies-using-resume-roo/lufthansa.svg'
import varta from '../../../public/companies-using-resume-roo/varta.svg'
import weller from '../../../public/companies-using-resume-roo/weller.svg'
import Picture from 'next-export-optimize-images/picture'

const companies = [
  { name: 'Varta', image: varta },
  { name: 'Lenovo', image: lenovo },
  { name: 'BBC', image: bbc },
  { name: 'Weller', image: weller },
  { name: 'British Airways', image: britishAirways },
  { name: 'Lufthansa', image: lufthansa }
]

const UsedByCompanies = () => {
  return (
    <div>
      <h3 className='text-gray-500 text-sm pl-8'>Used by candidates from</h3>

      <div className='relative flex h-[160px] overflow-x-hidden'>
        <div className='absolute top-0 py-12 motion-safe:animate-marquee whitespace-nowrap flex space-x-32 px-16'>
          {companies.map(company => (
            <Picture
              key={company.name}
              src={company.image}
              alt={company.name}
              width={140}
              height={120}
            />
          ))}
        </div>

        <div className='absolute top-0 py-12 motion-safe:animate-marquee2 whitespace-nowrap flex space-x-32 px-16'>
          {companies.map(company => (
            <Picture
              key={company.name}
              src={company.image}
              alt={company.name}
              width={140}
              height={120}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default UsedByCompanies
