import Testimonials from '@/components/index-components/testimonials/testimonials'
import Faq from './faq/faq'
import Features from './features/features'
import FinalCta from './final-cta/final-cta'
import Hero from './hero/hero'
import MidPageCta from './mid-page-cta/mid-page-cta'
import Pricing from './pricing/pricing'
import UsedByCompanies from './used-by-companies/used-by-companies'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'
import ExplainerVideo from '../explainer-video/explainer-video'
import Footer from '../footer/footer'

/*
  This page was written under the guidance of
  https://www.broadplace.com/blog/seo-for-saas-the-anatomy-of-the-perfect-saas-landing-page-with-templates/
  using the 'persona' approach.
*/
const ResumesForAustralia = () => {
  const { t } = useTranslation(['resumes-for-australia'])

  return (
    <>
      <Head>
        <title>{t('hero-subtitle')}</title>
        <meta name='description' content={t('hero-subsubtitle')!} />
      </Head>

      <main className='space-y-0 pt-8 text-lg main'>
        <div className='container pt-0 pb-0'>
          <Hero />
        </div>
        <div className='bg-primary-700'>
          <div className='container'>
            <ExplainerVideo />
          </div>
        </div>
        <div className='bg-gray-100 px-6 py-12'>
          <Features />
        </div>
        <div className='bg-primary-700 text-white py-16 px-4'>
          <MidPageCta />
        </div>
        <div className='pb-0'>
          <Testimonials />
          <UsedByCompanies />
        </div>
        <div className='bg-gray-100 py-16 px-4 flex justify-center'>
          <Pricing />
        </div>
        <div className='container px-6'>
          <Faq />
        </div>
        <div className='bg-primary-700 text-white py-16 px-4'>
          <FinalCta />
        </div>
      </main>
      <Footer />
    </>
  )
}

export default ResumesForAustralia
