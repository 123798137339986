import { useTranslation } from 'next-i18next'
// import ava from '../../../public/testimonials/ava-from-france.png'
// import hitoshi from '../../../public/testimonials/hitoshi-from-japan.png'
// import noah from '../../../public/testimonials/noah-from-south-africa.png'
// import sophia from '../../../public/testimonials/sophia-from-brazil.png'
import Picture from 'next-export-optimize-images/picture'

import benito from './benito.jpg'
import diana from './diana.jpg'
import erika from './erika.jpg'
import jadson from './jadson.jpg'

const testimonials = [
  {
    name: 'Benito',
    country: 'Brazil',
    image: benito
  },
  {
    name: 'Erika',
    country: 'Japan',
    image: erika
  },
  {
    name: 'Diana',
    country: 'France',
    image: diana
  },
  {
    name: 'Jadson',
    country: 'South Africa',
    image: jadson
  }
]

export default function Testimonials() {
  const { t } = useTranslation('index')

  return (
    <div className='container space-y-8 pt-4 pb-8 px-8 sm:px-0'>
      <ul className='list-none grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-4'>
        {testimonials.map(({ country, image, name }, index) => (
          <li
            key={name}
            className='p-6 rounded-xl border border-gray-300 space-y-4 flex-1 shadow-md bg-white'
          >
            <div className='flex space-x-4 items-top'>
              <Picture
                className='rounded-xl border-2 shadow-md'
                width={76}
                height={109}
                alt={name}
                src={image}
              />
              <div>
                <p className='text-xl font-semibold'>{name}</p>
                <p className='text-sm text-gray-700'>
                  {t(`from ${country.toLowerCase()}`)}
                </p>
              </div>
            </div>
            <p className='italic'>{t(`testimonial-${index}-p`)}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}
