import { IconArrowRight, IconZoomIn } from '@tabler/icons-react'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import Link from '../../link/link'
import Button from '../../tailwind/button'
import Resume1 from './resume-1.png'
import Picture from 'next-export-optimize-images/picture'
import { useState } from 'react'

const Hero = () => {
  const { t } = useTranslation('resumes-for-australia')
  const [isResumeZoomed, setIsResumeZoomed] = useState(false)

  const toggleResumeZoom = () => {
    setIsResumeZoomed(!isResumeZoomed)
  }

  return (
    <div>
      <div
        className={classNames(
          'grid grid-cols-1 gap-8 lg:grid-cols-2 items-center px-4 pt-4',
          'lg-grid-cols-2 lg:px-0'
        )}
      >
        <div className='space-y-8 lg:pb-16'>
          <h1 className='text-5xl tracking-wide capitalize text-center md:text-left'>
            {t('hero-title')}
          </h1>
          <h2 className='text-xl font-normal'>{t('hero-subtitle')}</h2>

          <p>
            <Link passHref href={'/resumes/'} className='block'>
              <Button
                color='primary'
                size='lg'
                className='text-xl'
                fullWidth={true}
              >
                <span>{t('hero-cta')}</span>
                <IconArrowRight />
              </Button>
            </Link>
          </p>
        </div>
        <div className='relative overflow-y-hidden h-full min-h-[300px] flex justify-center'>
          <div
            className='absolute cursor-pointer z-0 scale-100 transition-all'
            onClick={() => toggleResumeZoom()}
          >
            <p className='flex gap-2 text-gray-600 text-sm py-2 justify-end animate-bounce'>
              <IconZoomIn className='w-6 h-6' />
              {t('hero-see-an-example')}
            </p>
            <Picture
              alt='Resume 1 - RebelResumes.com'
              src={Resume1}
              width={600}
              height={600}
              className='rounded-sm border'
            />
          </div>
        </div>
      </div>

      {isResumeZoomed && (
        <div
          className='fixed cursor-pointer z-50 top-0 left-0 p-8 h-screen w-full bg-black bg-opacity-50 flex justify-center overflow-y-scroll'
          onClick={() => toggleResumeZoom()}
        >
          <Picture
            alt='Resume 1 - RebelResumes.com'
            src={Resume1}
            width={927}
            height={3014}
            className='rounded-sm border'
          />
        </div>
      )}
    </div>
  )
}

export default Hero
