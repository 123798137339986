import PlanSelector from '@/components/plan-selector/plan-selector'
import { useTranslation } from 'next-i18next'

const Pricing = () => {
  const { t } = useTranslation(['resumes-for-australia'])

  return (
    <div>
      <h2 className='text-3xl text-center'>{t('pricing-header')}</h2>
      <p className='mx-auto max-w-[600px] text-gray-700 text-center'>
        {t('pricing-sub')}
      </p>

      <div className='mt-8'>
        <PlanSelector hidePurchaseButtons={true} />
      </div>
    </div>
  )
}

export default Pricing
