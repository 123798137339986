import { create } from 'zustand'
import { productService } from '../services/product-service'
import { Payment } from '@stripe/firestore-stripe-payments'
import { paymentService } from '../services/payment-service'
import { StripeProduct } from '@rebelresumes/contracts'

// TODO only accept USD/AUD or should we offer a currency selector?
export const stripeUnitsToPrice = (units: number) =>
  (units / 100).toFixed(0).toString()

interface PaymentStoreState {
  products: StripeProduct[] | undefined
  payments: Payment[] | undefined
}

const initialState: PaymentStoreState = {
  products: undefined,
  payments: undefined
}

export interface PaymentStore extends PaymentStoreState {
  fetchProducts: () => Promise<void>
  fetchPayments: () => Promise<void>
}

export const usePaymentStore = create<PaymentStore>((set, get) => ({
  ...initialState,
  fetchProducts: async () => {
    const products = await productService.list()
    set({ products })
  },
  fetchPayments: async () => {
    const payments = await paymentService.list()
    set({ payments })
  }
}))
